import { loadState, deleteState } from "@/utis/localStorage";
import { PageFilters } from "./common";

const KEY = "PAGE_FILTERS";
export const DEFAULT_NAME = "Default";
export const DEFAULT_FILTER_VALUE = {
  name: DEFAULT_NAME,
  value: null,
  notification: null,
  filterIcon: "Save",
  overriddenBy: null,
  color: "#7E7E7E",
};
const getDefaultStateUpdated = (key: string) => {
  const defaultState = loadState<PageFilters>(key);

  if (!defaultState) return null;
  if (!Array.isArray(defaultState.arbitrages)) {
    deleteState(KEY);
    return null;
  }
  if (defaultState.arbitrages.length > 1 && typeof defaultState.arbitrages[1] === "string") {
    deleteState(KEY);
    return null;
  }
  return defaultState;
};

export const pageFilterDefault = {
  arbitrages: [DEFAULT_FILTER_VALUE],
  middles: [DEFAULT_FILTER_VALUE],
  freebet: [DEFAULT_FILTER_VALUE],
  lowhold: [DEFAULT_FILTER_VALUE],
  expectedvalue: [DEFAULT_FILTER_VALUE],
  "odds-screen": [DEFAULT_FILTER_VALUE],
  game: [DEFAULT_FILTER_VALUE],
};

export const initialState: PageFilters = getDefaultStateUpdated(KEY) || pageFilterDefault;
