import { loadState } from "@/utis/localStorage";
import { OddsDisplay } from "@/components/FormattedOdds/common";
import { Filters, LocationType } from "./common";

const KEY = "FILTERS";
const LOCATION_KEY = "LOCATIONS";

export const filterDefault = {
  oddsDisplay: OddsDisplay.AMERICAN,
  location: {
    region: undefined,
    country: undefined,
  },
  isCollapsed: false,
  newSportsBookAdded: {},
  isFilterMigrated: false,
  isFilterMigratedV2: false,
};

const loadStateWithLocationKey = () => {
  const globalSetting = loadState<Filters>(KEY);
  if (!globalSetting) return undefined;
  const location = loadState<LocationType>(LOCATION_KEY);
  if (!globalSetting.location && location) {
    globalSetting.location = location;
  }
  return globalSetting;
};

export const initialState: Filters = loadStateWithLocationKey() || filterDefault;
