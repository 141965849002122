import { Box, Stack, Typography, filledInputClasses, styled, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import VerificationInput from "react-verification-input";
import { useMutation } from "@apollo/client";
import PageSpinner from "@/components/PageSpinner";
import { updateUserRefreshTokens } from "@/user/common";
import ErrorBoundaryPopup from "@/components/ErrorBoundary/ErrorBoundaryPopup";
import { useTranslations } from "next-intl";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MobileCheckIcon from "@/components/Icons/MobileCheckIcon";
import {
  VERIFY_AUTHENTICATOR,
  VerifyAuthenticator,
  VerifyAuthenticatorVariables,
} from "@/components/AuthenticatorSetup/authenticatorVerification.graphql";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { useTheme } from "@/theme/ThemeContext";
import { useDispatch } from "react-redux";
import { filtersAction } from "@/store/filter";
import { LoginAuthenticationMethodEnum, USER_SIGNUP, UserSignup, UserSignupVariables } from "./common";
import { Verify, VerifyEmail, VerifyEmailVariables, VERIFY_EMAIL } from "./verifyEmail.graphql";


export const StyledTurnstile = styled(Turnstile)`
  iframe {
    width: 100% !important;
    display: hidden;
  }
`;

export const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.text.primary};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
    height: max-content;
    margin: auto;
    position: relative;
    width: 425px;

    ${theme.breakpoints.down("md")} {
      max-width: 425px;
      width: calc(100% - ${theme.spacing(3)});
      padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    }

  `,
);

const StyledVerificationInputContainer = styled("div")(({ theme }) => ({
  "& .vi__character": {
    height: "100%",
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: "8px",
    border: "none",
    color: theme.palette.text.primary,
    fontWeight: 600,
    backgroundColor: theme.palette.grayScale.sixpercent,
  },
}));

type LoginVerificationCodeProps = {
  hashCode: string;
  emailAddress: string;
  onClose: () => void;
  setShowPlansModal: (val: boolean) => void;
  verificationType: LoginAuthenticationMethodEnum;
};

function LoginVerificationCode(props: LoginVerificationCodeProps) {
  const {
    emailAddress,
    hashCode,
    onClose: onParentModalClose,
    setShowPlansModal,
    verificationType: verificationTypeProps,
  } = props;
  const theme = useTheme();
  const [error, setError] = useState<Error | null>(null);
  const [verificationCode, setVerificationCode] = useState<string>();
  const [verificationType, setVerificationType] = useState(verificationTypeProps);
  const [forcedVerification, setForcedVerification] = useState(false);
  const turnStileRef = useRef<TurnstileInstance>(null);
  const dispatch = useDispatch();
  const onError = (err: Error, reset: () => void) => {
    reset();
    setVerificationCode(undefined);
    setError(err);
  };
  const onCompleted = (resData: Verify) => {
    const { refreshToken, userId, isNewUser: newUserFlag } = resData;
    if (refreshToken && userId) {
      updateUserRefreshTokens({
        refreshToken: { token: refreshToken },
        userId,
      });
      if (newUserFlag) {
        onParentModalClose?.();
        setShowPlansModal(true);
      } else {
        onParentModalClose?.();
        dispatch(filtersAction.clearAllFilters());
        setTimeout(() => window.location.reload(), 2000);
      }
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    const handleResize = () => {
      if (inputRef.current) {
        inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [handleEmailVerification, { loading: verifyEmailLoading, reset: verifyEmailReset }] = useMutation<
    VerifyEmail,
    VerifyEmailVariables
  >(VERIFY_EMAIL, {
    onError: (err) => {
      onError(err, verifyEmailReset);
    },
    onCompleted: async (resData) => {
      onCompleted(resData.user.email.verify);
    },
  });

  const [handleAuthenticatorVerification, { loading: verifyAuthenticatorLoading, reset: verifyAuthenticatorReset }] = useMutation<
    VerifyAuthenticator,
    VerifyAuthenticatorVariables
  >(VERIFY_AUTHENTICATOR, {
    onError: (err) => {
      onError(err, verifyAuthenticatorReset);
    },
    onCompleted: async (resData) => {
      onCompleted(resData.user.authenticator.verify);
    },
  });

  const [handleUserSignup, { loading: userSignUpLoading }] = useMutation<UserSignup, UserSignupVariables>(USER_SIGNUP, {
    onError: (err) => {
      onError(err, verifyEmailReset);
      turnStileRef.current?.reset();
    },
  });

  const onInputComplete = (code: string) => {
    const variables = {
      variables: {
        hashCode,
        code: code.toUpperCase(),
      },
    };
    if (verificationType === LoginAuthenticationMethodEnum.EMAIL) {
      handleEmailVerification(variables);
    } else {
      handleAuthenticatorVerification(variables);
    }
  };

  const onCloseErrorPopup = () => {
    setError(null);
    verifyEmailReset();
    verifyAuthenticatorReset();
    setVerificationCode(undefined);
  };

  const changeVerificationTypeToEmail = (captchaToken: string) => {
    handleUserSignup({
      variables: {
        email: emailAddress,
        cfTurnstileToken: captchaToken!,
        hashCode,
        forcedMethod: LoginAuthenticationMethodEnum.EMAIL,
      },
    });
    setVerificationType(LoginAuthenticationMethodEnum.EMAIL);
  };

  const handleVerify = (captchaTokenApi: string) => {
    changeVerificationTypeToEmail(captchaTokenApi);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const t = useTranslations("verification");

  return (
    <Stack direction="column" pb={3} gap={isMobile ? 4 : 8}>
      <Stack direction="column" gap={2} alignItems="center" justifyContent="center">
        {verificationType === LoginAuthenticationMethodEnum.EMAIL ? (
          <MailOutlineIcon sx={{ fontSize: isMobile ? 24 : 33, color: "text.primary" }} />
        ) : (
          <MobileCheckIcon sx={{ fontSize: isMobile ? 24 : 33, color: "text.primary" }} />
        )}
        <Typography variant="body1Bold" color="text.primary" fontSize={isMobile ? 20 : 30} mb={3} mt={2}>
          {verificationType === LoginAuthenticationMethodEnum.EMAIL
            ? t("verification_email_sent", { emailAddress })
            : t("verification_authenticator")}
        </Typography>
      </Stack>
      <Stack>
        {verifyEmailLoading || verifyAuthenticatorLoading || userSignUpLoading ? (
          <PageSpinner text="" height="auto" />
        ) : (
          <StyledVerificationInputContainer>
            <VerificationInput
              value={verificationCode}
              ref={inputRef}
              onComplete={onInputComplete}
              length={6}
              autoFocus
              onChange={(ev) => setVerificationCode(ev.toUpperCase())}
              classNames={{
                container: filledInputClasses.input,
              }}
              containerProps={{
                style: {
                  alignSelf: "center",
                  margin: "0 auto",
                },
              }}
            />
          </StyledVerificationInputContainer>
        )}
        {verificationType === LoginAuthenticationMethodEnum.AUTHENTICATOR && (
          <Typography
            onClick={() => setForcedVerification(true)}
            variant="smallText"
            mt={1}
            color="text.secondary"
            sx={{ cursor: "pointer" }}
          >
            {t("send_email_instead")}
          </Typography>
        )}
        {forcedVerification && (
          <Box height={65} my={2}>
            <StyledTurnstile
              ref={turnStileRef}
              siteKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
              options={{ theme: theme.palette.mode, retry: "never" }}
              style={{ width: "100%" }}
              onSuccess={handleVerify}
            />
          </Box>
        )}
      </Stack>
      {error && <ErrorBoundaryPopup message={error.message} onClose={onCloseErrorPopup} />}
    </Stack>
  );
}

export default LoginVerificationCode;
